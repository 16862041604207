import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { _getDomain } from "../components/helpers.js";
import { clearCart } from "../redux/actions/cart";
import { Header, Footer, TownLink } from "../components";
import { ReactComponent as CatConfirm } from "../img/cat-order-confirm.svg";
import "../css/order-complete-page.css";

export default function OrderPending() {
    const dispatch = useDispatch();

    const userToken = useSelector((state) => state.user.user.token);

    const customConfirmOrderImg = window.CONFIG_order_confirm_image;

    useEffect(() => {
        dispatch(clearCart());
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
            }}
        >
            <Header />
            <Container sx={{ flexGrow: 1 }}>
                <h1 className="page-title" lang="ru">
                    Ожидание оплаты
                </h1>
                <div className="order-complete-page">
                    <div className="order-complete--logo-container">
                        {customConfirmOrderImg ? (
                            <img
                                src={customConfirmOrderImg}
                                className="order-complete--logo"
                                alt="Ожидание оплаты"
                            />
                        ) : (
                            <CatConfirm className="order-complete--logo" />
                        )}
                    </div>
                    <div className="order-complete-page--content-container">
                        <p>
                            {userToken
                                ? "Ожидаем оплату заказа. Если вы уже его оплатили, то просто нажмите кнопку «Мои заказы»."
                                : "Ожидаем оплату заказа. Если вы уже оплатили заказ, то наш оператор скоро свяжется с вами."}
                        </p>
                        {userToken ? (
                            <TownLink
                                className="btn btn--action"
                                to="/account#orders"
                                style={{ marginTop: "15px" }}
                            >
                                В мои заказы
                            </TownLink>
                        ) : (
                            <TownLink
                                className="btn btn--action"
                                to="/"
                                style={{ marginTop: "15px" }}
                            >
                                На главную страницу
                            </TownLink>
                        )}
                    </div>
                </div>
            </Container>
            <Footer />
        </Box>
    );
}
