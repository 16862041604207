import { useNavigate } from "react-router";
import useChoosenTown from "./useChoosenTown";

const useTownNavigate = () => {
    const navigate = useNavigate();
    const { choosenTown } = useChoosenTown();

    let rootTownPathname = "/";

    if (choosenTown) {
        rootTownPathname += choosenTown.slug;
    }

    const navigateTo = (path, options, search = "") => {
        if (path === "/") {
            navigate(rootTownPathname + search, options);
        } else {
            let pathString = "";
            if (choosenTown) {
                pathString += "/" + choosenTown.slug;
            }
            pathString += path;
            navigate(pathString + search, options);
        }
    };
    return { navigateTo, rootTownPathname };
};

export default useTownNavigate;
