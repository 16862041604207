import {
    Sales,
    Contacts,
    Account,
    Checkout,
    Page,
    OrderComplete,
    Product,
    CategoryPage,
    SearchPage,
    NotFound,
    BonusesPage,
    OrderFailed,
    QrMobile,
    OrderPending,
    AuthFromVkApp,
} from "../pages";

export const getPagesList = (config) => [
    { path: "product/*", element: <Product /> },
    { path: "checkout", element: <Checkout /> },
    { path: "sales", element: <Sales /> },
    { path: "contacts", element: <Contacts />, exact: true },
    { path: "account", element: <Account /> },
    { path: "order-complete", element: <OrderComplete /> },
    { path: "order-pending", element: <OrderPending /> },
    { path: "order-failed", element: <OrderFailed /> },
    { path: "search", element: <SearchPage /> },
    { path: "category/:categoryName", element: <CategoryPage /> },
    { path: "product-category/:categoryName", element: <CategoryPage /> },
    { path: "bonuses", element: <BonusesPage /> },
    { path: "qr-mobile", element: <QrMobile /> },
    { path: "authFromVkApp", element: <AuthFromVkApp /> },
    { path: "*", element: <Page /> },
    { path: "not-found", element: <NotFound /> },
];
