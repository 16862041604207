import React, { forwardRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
    Alert,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText,
} from "@mui/material";
import {
    isToday,
    isWithinInterval,
    format,
    compareAsc,
    addMinutes,
    addDays,
    set,
    getDayOfYear,
    getTime,
    getDay,
    roundToNearestMinutes,
    endOfDay,
    getUnixTime,
    isBefore,
    isAfter,
    eachDayOfInterval,
    getHours,
    getMinutes,
    isEqual,
    startOfDay,
    isSameDay,
    parse,
} from "date-fns";
import useWorkingStatus from "../../hooks/useWorkingStatus";
import {
    _checkDateInterval,
    _checkWorkingInterval,
    _isObjEmpty,
} from "../helpers";

const PreorderForm = forwardRef(
    (
        {
            preorderDate,
            preorderTime,
            handlePreorderDateChange,
            handlePreorderTimeChange,
            asSoonAsPosible,
            error,
            helperText,
        },
        ref
    ) => {
        const {
            workingStatus,
            maintenanceStatus,
            maintenanceDateStart,
            maintenanceDateEnd,
        } = useWorkingStatus();

        const { promocode: cartPromocode, conditionalPromocode } = useSelector(
            (state) => state.cart
        );

        const promocode = conditionalPromocode || cartPromocode;

        const config = useSelector((state) => state.config.data);

        const cartProducts = useSelector((state) =>
            Object.values(state.cart.items)
        );
        const categories = useSelector((state) => state.products.categories);
        const bonusProduct = useSelector((state) => state.cart.bonusProduct);

        // Получаем выбранный пользователем день недели (0 = понедельник)
        const preorderDayOfWeek =
            getDay(preorderDate) === 0 ? 6 : getDay(preorderDate) - 1;

        // Массив локализованных дней недели для рендера
        const localDaysOfWeek = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
        // Получаем доступное время для заказа в выбранный день
        const preorderOrderingTime = config.orderingTime[preorderDayOfWeek] || [
            config.CONFIG_schedule_ordering_start,
            config.CONFIG_schedule_ordering_end,
        ];

        // Блок для проверки рендера опций "Как можно скорее" и сегодняшней даты
        // Получаем сегодняшний день недели
        const todayDayOfWeek =
            getDay(new Date()) === 0 ? 6 : getDay(new Date()) - 1;
        //Получаем доступное время для заказа на сегодня
        const todayOrderingTime = config.orderingTime[todayDayOfWeek] || [
            config.CONFIG_schedule_ordering_start,
            config.CONFIG_schedule_ordering_end,
        ];

        // Если конец сегодняшнего дня переходит на след. день, конец дня устанавливается в 23:59
        const isTodayWorkAfterMidnight =
            parseInt(todayOrderingTime[0].slice(0, 2)) >=
            parseInt(todayOrderingTime[1].slice(0, 2));

        // Устанавливаем конечное время для заказа сегодня
        let todayEndWorkTime = set(new Date(), {
            hours: isTodayWorkAfterMidnight
                ? 23
                : todayOrderingTime[1].slice(0, 2),
            minutes: isTodayWorkAfterMidnight
                ? 59
                : todayOrderingTime[1].slice(3, 5),
            seconds: 0,
            milliseconds: 0,
        });

        // Устанавливаем начальное время для заказа в формате даты
        let startWorkDate = set(new Date(), {
            hours: preorderOrderingTime[0].slice(0, 2),
            minutes: preorderOrderingTime[0].slice(3, 5),
            seconds: 0,
            milliseconds: 0,
        });

        // Если конец дня предзаказа переходит на след. день, конец дня устанавливается в 23:59
        const isPreorderWorkAfterMidnight =
            parseInt(preorderOrderingTime[0].slice(0, 2)) >=
            parseInt(preorderOrderingTime[1].slice(0, 2));

        // Устанавливаем конечное время для заказа в формате даты
        let endWorkDate = set(new Date(), {
            hours: isPreorderWorkAfterMidnight
                ? 23
                : preorderOrderingTime[1].slice(0, 2),
            minutes: isPreorderWorkAfterMidnight
                ? 59
                : preorderOrderingTime[1].slice(3, 5),
            seconds: 0,
            milliseconds: 0,
        });

        // Если конец прошлого дня переходит на сегодняшний, учитываем это время
        const dayBeforeDayOfWeek =
            preorderDayOfWeek === 0 ? 6 : preorderDayOfWeek - 1;

        // Получаем доступное время для заказа в предыдущий перед выбранным днем
        const dayBeforeOrderingTime = config.orderingTime[
            dayBeforeDayOfWeek
        ] || [
            config.CONFIG_schedule_ordering_start,
            config.CONFIG_schedule_ordering_end,
        ];

        const isDayBeforeWorkAfterMidnight =
            parseInt(dayBeforeOrderingTime[0].slice(0, 2)) >=
            parseInt(dayBeforeOrderingTime[1].slice(0, 2));

        const dayBeforeAfterMidnightEndWorkTime = set(new Date(), {
            hours: dayBeforeOrderingTime[1].slice(0, 2),
            minutes: dayBeforeOrderingTime[1].slice(3, 5),
            seconds: 0,
            milliseconds: 0,
        });

        // Если уже выбранное время после смены даты не входит в доступный интервал, сбрасываем время
        if (preorderTime) {
            if (
                !isWithinInterval(preorderTime, {
                    start: isToday(preorderDate) ? new Date() : startWorkDate,
                    end: endWorkDate,
                })
            ) {
                if (!isDayBeforeWorkAfterMidnight) {
                    handlePreorderTimeChange("");
                } else if (
                    !isWithinInterval(preorderTime, {
                        start: startOfDay(new Date()),
                        end: dayBeforeAfterMidnightEndWorkTime,
                    })
                ) {
                    handlePreorderTimeChange("");
                }
            }
        }

        // Если заказ на сегодня, устанавливаем начальное время от текущего времени
        if (isToday(preorderDate)) {
            if (isAfter(new Date(), startWorkDate)) {
                startWorkDate = new Date();
                startWorkDate = roundToNearestMinutes(startWorkDate, {
                    nearestTo: 30,
                    roundingMethod: "ceil",
                });
            }
        }

        // Если заказ на сегодня и текущее время + задержка больше начального, передвигаем начальное время
        if (isToday(preorderDate) && config.CONFIG_time_order_delay) {
            if (
                isAfter(
                    addMinutes(new Date(), config.CONFIG_time_order_delay),
                    startWorkDate
                )
            ) {
                startWorkDate = addMinutes(
                    new Date(),
                    config.CONFIG_time_order_delay
                );
                startWorkDate = roundToNearestMinutes(startWorkDate, {
                    nearestTo: 30,
                    roundingMethod: "ceil",
                });
            }
        }

        // Создаем массив доступных времён для заказа
        const hoursArray = [];

        // Добавляем в массив время работы вчерашнего дня после полуночи
        if (isDayBeforeWorkAfterMidnight) {
            let tempDate;

            // Если выбран сегодняшний день, добавляем часы в промежутке от текущего времени до конца вчерашнего рабочего дня
            if (isToday(preorderDate)) {
                tempDate = new Date();
                if (config.CONFIG_time_order_delay) {
                    tempDate = addMinutes(
                        new Date(),
                        config.CONFIG_time_order_delay
                    );
                }
                tempDate = roundToNearestMinutes(tempDate, {
                    nearestTo: 30,
                    roundingMethod: "ceil",
                });
            }
            // Если выбран не сегодняшний день, добавляем часы в промежутке от полуночи до конца вчерашнего рабочего дня
            else {
                tempDate = startOfDay(new Date());
            }

            while (
                compareAsc(tempDate, dayBeforeAfterMidnightEndWorkTime) < 1
            ) {
                hoursArray.push(tempDate);
                tempDate = addMinutes(tempDate, 30);
            }
        }

        // Добавляем в массив сегодняшнее время работы
        if (preorderOrderingTime[0] && preorderOrderingTime[1]) {
            while (compareAsc(startWorkDate, endWorkDate) < 1) {
                hoursArray.push(startWorkDate);
                startWorkDate = addMinutes(startWorkDate, 30);
            }
        }

        // Проверяем чтобы последнее доступное время не переходило на следующий день
        if (
            compareAsc(
                hoursArray[hoursArray.length - 1],
                endOfDay(new Date())
            ) == 1
        ) {
            hoursArray.pop();
        }

        // Создаем массив недоступных для заказа дней недели, проверкой есть ли в этот день доступное время
        const unavailableDays = config.orderingTime
            ?.map((el, inx) => {
                // Добавляем к промежутку доступного времени, время работы вчерашнего дня после полуночи
                const dayBeforeDayOfWeek = inx === 0 ? 6 : inx - 1;

                const dayBeforeOrderingTime = config.orderingTime[
                    dayBeforeDayOfWeek
                ] || [
                    config.CONFIG_schedule_ordering_start,
                    config.CONFIG_schedule_ordering_end,
                ];

                const isDayBeforeWorkAfterMidnight =
                    parseInt(dayBeforeOrderingTime[0].slice(0, 2)) >=
                    parseInt(dayBeforeOrderingTime[1].slice(0, 2));

                if ((!el[0] || !el[1]) && !isDayBeforeWorkAfterMidnight) {
                    return inx;
                }
            })
            .filter((el) => {
                if (el || el === 0) return true;
            });

        // Создаем массив доступных дней для заказа из ближайших 30
        // Добавляем к промежутку доступного времени, время работы вчерашнего дня после полуночи
        const yesterdayDayOfWeek =
            todayDayOfWeek === 0 ? 6 : todayDayOfWeek - 1;

        const yesterdayOrderingTime = config.orderingTime[
            yesterdayDayOfWeek
        ] || [
            config.CONFIG_schedule_ordering_start,
            config.CONFIG_schedule_ordering_end,
        ];

        const isYesterdayWorkAfterMidnight =
            parseInt(yesterdayOrderingTime[0].slice(0, 2)) >=
            parseInt(yesterdayOrderingTime[1].slice(0, 2));

        const yesterdayAfterMidnightEndWorkTime = set(new Date(), {
            hours: yesterdayOrderingTime[1].slice(0, 2),
            minutes: yesterdayOrderingTime[1].slice(3, 5),
            seconds: 0,
            milliseconds: 0,
        });

        // Доступно ли сегодня время для предзаказа
        const isPreorderTimeAvailableToday =
            isBefore(
                addMinutes(new Date(), config.CONFIG_time_order_delay),
                todayEndWorkTime
            ) ||
            (isYesterdayWorkAfterMidnight &&
                isBefore(
                    addMinutes(new Date(), config.CONFIG_time_order_delay),
                    yesterdayAfterMidnightEndWorkTime
                ));

        const isWithinTodayWorkingInterval = _checkWorkingInterval(
            todayOrderingTime[0],
            todayOrderingTime[1]
        );

        const datesArray =
            (config.CONFIG_disable_preorder_unworktime &&
                !isWithinTodayWorkingInterval) ||
            config.CONFIG_preorder_limit_type === "disable" ||
            (!isPreorderTimeAvailableToday &&
                config.CONFIG_preorder_limit_type === "currentDay")
                ? []
                : config.CONFIG_preorder_limit_type === "currentDay"
                ? [new Date()]
                : eachDayOfInterval({
                      start:
                          // Определяем, нужно ли добавлять в массив сегодняшний день
                          isPreorderTimeAvailableToday
                              ? new Date()
                              : addDays(new Date(), 1),
                      end: addDays(
                          new Date(),
                          config.CONFIG_preorder_limit_type === "days" &&
                              config.CONFIG_preorder_limit_days
                              ? config.CONFIG_preorder_limit_days
                              : 30
                      ),
                  });

        const preorderLimitDateStart = parse(
            config.CONFIG_disable_preorders_start_date,
            "dd.MM.yyyy HH:mm",
            new Date()
        );

        const preorderLimitDateEnd = parse(
            config.CONFIG_disable_preorders_end_date,
            "dd.MM.yyyy HH:mm",
            new Date()
        );

        const isPreorderDateSameAsLimitDateStart = isSameDay(
            preorderDate,
            preorderLimitDateStart
        );

        const isPreorderDateSameAsLimitDateEnd = isSameDay(
            preorderDate,
            preorderLimitDateEnd
        );

        // Ссоздаем массив недоступных для заказ дней недели у действующего промокода
        const unavailablePromocodeDays = promocode.days
            ?.map((el, inx) => {
                if (!el) {
                    return inx;
                }
            })
            .filter((el) => {
                if (el || el === 0) return true;
            });

        // Ссоздаем массив недоступных для предзаказа дней у категорий по времени
        const unavailableCaterogiesDays = new Set();
        const categoriesStartTimeLimits = [];
        const categoriesEndTimeLimits = [];
        const limitedCategoriesNames = [];
        cartProducts.forEach((cartProduct) => {
            cartProduct.items[0].categories.forEach((categoryId) => {
                const category = categories.find(
                    (category) => category.term_id === categoryId
                );
                if (category && category.useTimeLimit) {
                    if (
                        !limitedCategoriesNames.includes(`«${category.name}»`)
                    ) {
                        limitedCategoriesNames.push(`«${category.name}»`);
                    }

                    if (Array.isArray(category.days)) {
                        category.days.forEach((day, inx) => {
                            if (!day) {
                                unavailableCaterogiesDays.add(inx);
                            }
                        });
                    }

                    if (
                        category.timeLimitStart &&
                        !categoriesStartTimeLimits.includes(
                            category.timeLimitStart
                        )
                    ) {
                        categoriesStartTimeLimits.push(category.timeLimitStart);
                    }
                    if (
                        category.timeLimitEnd &&
                        !categoriesEndTimeLimits.includes(category.timeLimitEnd)
                    ) {
                        categoriesEndTimeLimits.push(category.timeLimitEnd);
                    }
                }
            });
        });
        // Сортируем для определения самого позднего времени начала и самого раннего времени конца ограничения
        categoriesStartTimeLimits.sort().reverse();
        categoriesEndTimeLimits.sort();

        // Ссоздаем массив недоступных для предзаказа дней у бонусных продуктов
        const unavailableBonusProductDays = [];
        if (
            bonusProduct.id &&
            config.CONFIG_bonuses_use_limit_time &&
            config.CONFIG_bonuses_days &&
            config.CONFIG_bonuses_days.length
        ) {
            config.CONFIG_bonuses_days.forEach((day, index) => {
                if (!day) {
                    unavailableBonusProductDays.push(index);
                }
            });
        }

        const disabledBySettings =
            (config.CONFIG_preorder_limit_type === "currentDay" ||
                config.CONFIG_preorder_limit_type === "disable" ||
                config.CONFIG_disable_preorder_unworktime) &&
            !isWithinTodayWorkingInterval;

        useEffect(() => {
            if (
                workingStatus &&
                maintenanceStatus &&
                !disabledBySettings &&
                isWithinTodayWorkingInterval
            ) {
                handlePreorderDateChange("Как можно скорее");
            }
        }, [
            workingStatus,
            maintenanceStatus,
            disabledBySettings,
            isWithinTodayWorkingInterval,
        ]);

        // Проверяем день окончания действия промокода
        const promocodeEndDate = parse(
            promocode.endDateFormat,
            "dd.MM.yyyy HH:mm",
            new Date()
        );
        const isPreorderDateEndOfPromocode = isSameDay(
            preorderDate,
            promocodeEndDate
        );

        // Ограничение предзаказа настройкой "Отключить использование промокодов в предзаказах"
        const promocodesSettingsStartDate =
            config.CONFIG_preorder_disable_promocodes_start_date
                ? parse(
                      config.CONFIG_preorder_disable_promocodes_start_date,
                      "dd.MM.yyyy HH:mm",
                      new Date()
                  )
                : null;

        const isPromocodeSettingsStartDateToday = isSameDay(
            preorderDate,
            promocodesSettingsStartDate
        );

        const promocodesSettingsEndDate =
            config.CONFIG_preorder_disable_promocodes_end_date
                ? parse(
                      config.CONFIG_preorder_disable_promocodes_end_date,
                      "dd.MM.yyyy HH:mm",
                      new Date()
                  )
                : null;

        const isPromocodeSettingsEndDateToday = isSameDay(
            preorderDate,
            promocodesSettingsEndDate
        );

        const preorderDisabledByPromocodesSettings =
            !_isObjEmpty(promocode) &&
            config.CONFIG_preorder_disable_promocodes === "on"
                ? config.CONFIG_preorder_disable_promocodes_start_date &&
                  config.CONFIG_preorder_disable_promocodes_end_date
                    ? preorderTime &&
                      _checkDateInterval(
                          config.CONFIG_preorder_disable_promocodes_start_date,
                          config.CONFIG_preorder_disable_promocodes_end_date,
                          true,
                          preorderDate
                      )
                    : true
                : false;

        if (preorderDisabledByPromocodesSettings) {
            if (preorderDate && !asSoonAsPosible) {
                handlePreorderDateChange("");
            }
            if (preorderTime) {
                handlePreorderTimeChange("");
            }
        }

        const checkDateForSaleProduct = (preorderDate) => {
            return cartProducts.find((product) => {
                const saleEnd = product.items[0].options._sale_price_dates_to;

                if (!product.items[0].options?._sale_price || !saleEnd) {
                    return false;
                }

                const saleEndDate = endOfDay(
                    parse(saleEnd, "dd.MM.yyyy", new Date())
                );

                if (isAfter(preorderDate, saleEndDate)) {
                    return true;
                } else {
                    return false;
                }
            });
        };

        return (
            <div className="checkout--order-time">
                <h3>Когда приготовить заказ?</h3>

                {limitedCategoriesNames.length ? (
                    <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                        Предзаказ по категориям{" "}
                        {limitedCategoriesNames.join(", ")} возможен на
                        ограниченный период времени.
                    </Alert>
                ) : null}

                {disabledBySettings ? (
                    <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                        В данный момент заказы не принимаем. Возвращайтесь
                        позднее.
                    </Alert>
                ) : null}

                {config.CONFIG_preorder_text ? (
                    <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                        {config.CONFIG_preorder_text}
                    </Alert>
                ) : null}

                <Box sx={{ display: "flex" }}>
                    <FormControl
                        sx={{ minWidth: 120 }}
                        size="small"
                        error={error}
                    >
                        <InputLabel id="preorder-date-select-label">
                            Дата
                        </InputLabel>
                        <Select
                            label="Дата"
                            labelId="preorder-date-select-label"
                            id="preorder-date-select"
                            value={
                                asSoonAsPosible
                                    ? asSoonAsPosible
                                    : preorderDate
                                    ? getDayOfYear(preorderDate)
                                    : ""
                            }
                            autoWidth
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 500 } },
                            }}
                            sx={{
                                bgcolor: "var(--background-color)",
                            }}
                            disabled={disabledBySettings}
                        >
                            {!workingStatus ||
                            !maintenanceStatus ||
                            !isWithinTodayWorkingInterval ? null : (
                                <MenuItem
                                    key={"Как можно скорее"}
                                    value={"Как можно скорее"}
                                    onClick={() => {
                                        handlePreorderDateChange(
                                            "Как можно скорее"
                                        );
                                    }}
                                    divider={!!datesArray?.length}
                                    sx={{
                                        justifyContent: "center",
                                    }}
                                >
                                    Как можно скорее
                                </MenuItem>
                            )}

                            {datesArray.map((el, index, array) => {
                                // Блокируем дни, недоступные по настройкам предзаказов
                                const disabledByPreorderLimits =
                                    config.CONFIG_preorder_limit_type ===
                                        "dates" &&
                                    !isSameDay(el, preorderLimitDateStart) &&
                                    !isSameDay(el, preorderLimitDateEnd) &&
                                    _checkDateInterval(
                                        config.CONFIG_disable_preorders_start_date,
                                        config.CONFIG_disable_preorders_end_date,
                                        false,
                                        el
                                    );

                                // Блокируем дни, недоступные по настройкам промокодов
                                const promocodeSettingsEndDate =
                                    config.CONFIG_preorder_disable_promocodes_end_date
                                        ? parse(
                                              config.CONFIG_preorder_disable_promocodes_end_date,
                                              "dd.MM.yyyy HH:mm",
                                              new Date()
                                          )
                                        : null;

                                const dateForCheck = isSameDay(
                                    el,
                                    promocodeSettingsEndDate
                                )
                                    ? endOfDay(el)
                                    : el;

                                const disabledByPromocodeSettings =
                                    !_isObjEmpty(promocode) &&
                                    config.CONFIG_preorder_disable_promocodes ===
                                        "on" &&
                                    !!config.CONFIG_preorder_disable_promocodes_start_date &&
                                    !!config.CONFIG_preorder_disable_promocodes_end_date &&
                                    _checkDateInterval(
                                        config.CONFIG_preorder_disable_promocodes_start_date,
                                        config.CONFIG_preorder_disable_promocodes_end_date,
                                        true,
                                        dateForCheck
                                    );

                                // Блокируем дни, недоступные по действующему промокоду
                                const disabledByPromocode =
                                    unavailablePromocodeDays?.includes(
                                        getDay(el) === 0 ? 6 : getDay(el) - 1
                                    ) ||
                                    (!!promocode.endDate &&
                                        getUnixTime(el) > promocode.endDate);

                                // Блокируем дни, недоступные с ограниченными по времени категориями
                                const disabledByCategory =
                                    unavailableCaterogiesDays.has(
                                        getDay(el) === 0 ? 6 : getDay(el) - 1
                                    );

                                // Блокируем дни, недоступные с ограниченными по времени бонусными продуктами
                                const disabledByBonusProduct =
                                    unavailableBonusProductDays.includes(
                                        getDay(el) === 0 ? 6 : getDay(el) - 1
                                    );

                                // Блокируем выходные дни
                                const currentDayOfWeek =
                                    getDay(el) === 0 ? 6 : getDay(el) - 1;
                                const disabledBySchedule =
                                    unavailableDays?.includes(currentDayOfWeek);

                                // Блокируем дни закрытия сайта
                                const disabledByMaintenance =
                                    (isAfter(el, maintenanceDateStart) ||
                                        isEqual(el, maintenanceDateStart)) &&
                                    isBefore(
                                        set(el, { hours: 23, minutes: 50 }),
                                        maintenanceDateEnd
                                    );

                                // Блокируем дни окончания распродажи товаров
                                const disabledByProductSale =
                                    checkDateForSaleProduct(el);

                                const itemDisabled =
                                    disabledByPreorderLimits ||
                                    disabledByPromocode ||
                                    disabledByPromocodeSettings ||
                                    disabledBySchedule ||
                                    disabledByMaintenance ||
                                    disabledByCategory ||
                                    disabledByBonusProduct ||
                                    disabledByProductSale;

                                return (
                                    <MenuItem
                                        key={getDayOfYear(el)}
                                        value={getDayOfYear(el)}
                                        sx={{
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        }}
                                        divider={
                                            index < array.length - 1
                                                ? true
                                                : false
                                        }
                                        disabled={itemDisabled}
                                        onClick={() => {
                                            if (!itemDisabled) {
                                                handlePreorderDateChange(el);
                                            }
                                        }}
                                    >
                                        <span>
                                            {format(el, "d MMMM")}
                                            {", "}
                                            {localDaysOfWeek[currentDayOfWeek]}
                                        </span>
                                        {itemDisabled ? (
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "1",
                                                }}
                                            >
                                                {disabledBySchedule ||
                                                disabledByMaintenance
                                                    ? "Нерабочий день"
                                                    : disabledByPreorderLimits
                                                    ? "Недоступно"
                                                    : disabledByPromocode ||
                                                      disabledByPromocodeSettings
                                                    ? "Недоступно с промокодом"
                                                    : disabledByCategory
                                                    ? "Ограничено категорией"
                                                    : disabledByBonusProduct
                                                    ? "Недоступно с подарком"
                                                    : disabledByProductSale
                                                    ? "Ограничено скидкой"
                                                    : null}
                                            </div>
                                        ) : null}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>

                    {!asSoonAsPosible && preorderDate ? (
                        <FormControl
                            sx={{ minWidth: 120, ml: 1 }}
                            size="small"
                            error={error}
                        >
                            <InputLabel id="preorder-time-select-label">
                                Время
                            </InputLabel>
                            <Select
                                label="Время"
                                labelId="preorder-time-select-label"
                                id="preorder-time-select"
                                defaultValue={""}
                                value={preorderTime ? preorderTime : ""}
                                onChange={(e) => {
                                    handlePreorderTimeChange(e.target.value);
                                }}
                                autoWidth
                                MenuProps={{
                                    PaperProps: { sx: { maxHeight: 500 } },
                                }}
                                sx={{
                                    bgcolor: "var(--background-color)",
                                }}
                            >
                                {hoursArray.map((el, index, array) => {
                                    // Блокируем часы, недоступные по настройкам предзаказа

                                    let disabledByPreorderLimits = false;

                                    if (
                                        config.CONFIG_preorder_limit_type ===
                                            "dates" &&
                                        (isPreorderDateSameAsLimitDateStart ||
                                            isPreorderDateSameAsLimitDateEnd)
                                    ) {
                                        const settedPreorderLimitsStartDate =
                                            set(preorderLimitDateStart, {
                                                year: el.getFullYear(),
                                                month: el.getMonth(),
                                                date: el.getDate(),
                                            });

                                        const settedPreorderLimitsEndDate = set(
                                            preorderLimitDateEnd,
                                            {
                                                year: el.getFullYear(),
                                                month: el.getMonth(),
                                                date: el.getDate(),
                                            }
                                        );

                                        if (
                                            isPreorderDateSameAsLimitDateStart &&
                                            !isPreorderDateSameAsLimitDateEnd &&
                                            isWithinInterval(el, {
                                                start: settedPreorderLimitsStartDate,
                                                end: endOfDay(el),
                                            })
                                        ) {
                                            disabledByPreorderLimits = true;
                                        }

                                        if (
                                            !isPreorderDateSameAsLimitDateStart &&
                                            isPreorderDateSameAsLimitDateEnd &&
                                            isWithinInterval(el, {
                                                start: startOfDay(el),
                                                end: settedPreorderLimitsEndDate,
                                            })
                                        ) {
                                            disabledByPreorderLimits = true;
                                        }

                                        if (
                                            isPreorderDateSameAsLimitDateStart &&
                                            isPreorderDateSameAsLimitDateEnd &&
                                            isWithinInterval(el, {
                                                start: settedPreorderLimitsStartDate,
                                                end: settedPreorderLimitsEndDate,
                                            })
                                        ) {
                                            disabledByPreorderLimits = true;
                                        }
                                    }

                                    // Блокируем часы, недоступные по настройкам промокодов

                                    let disabledByPromocodesSettings = false;

                                    if (
                                        config.CONFIG_preorder_disable_promocodes ===
                                            "on" &&
                                        promocodesSettingsStartDate &&
                                        promocodesSettingsEndDate &&
                                        !_isObjEmpty(promocode)
                                    ) {
                                        const settedPromocodesSettingsStartDate =
                                            set(promocodesSettingsStartDate, {
                                                year: el.getFullYear(),
                                                month: el.getMonth(),
                                                date: el.getDate(),
                                            });

                                        const settedPromocodesSettingsEndDate =
                                            set(promocodesSettingsEndDate, {
                                                year: el.getFullYear(),
                                                month: el.getMonth(),
                                                date: el.getDate(),
                                            });

                                        if (
                                            isPromocodeSettingsStartDateToday &&
                                            !isPromocodeSettingsEndDateToday &&
                                            isWithinInterval(el, {
                                                start: settedPromocodesSettingsStartDate,
                                                end: endOfDay(el),
                                            })
                                        ) {
                                            disabledByPromocodesSettings = true;
                                        }

                                        if (
                                            !isPromocodeSettingsStartDateToday &&
                                            isPromocodeSettingsEndDateToday &&
                                            isWithinInterval(el, {
                                                start: startOfDay(el),
                                                end: settedPromocodesSettingsEndDate,
                                            })
                                        ) {
                                            disabledByPromocodesSettings = true;
                                        }

                                        if (
                                            isPromocodeSettingsStartDateToday &&
                                            isPromocodeSettingsEndDateToday &&
                                            isWithinInterval(el, {
                                                start: settedPromocodesSettingsStartDate,
                                                end: settedPromocodesSettingsEndDate,
                                            })
                                        ) {
                                            disabledByPromocodesSettings = true;
                                        }
                                    }

                                    // Блокируем часы, недоступные по дате промокода
                                    const settedPromocodeEndDate =
                                        promocodeEndDate
                                            ? set(promocodeEndDate, {
                                                  year: el.getFullYear(),
                                                  month: el.getMonth(),
                                                  date: el.getDate(),
                                              })
                                            : null;

                                    const disabledByPromocodeEndDate =
                                        settedPromocodeEndDate &&
                                        isPreorderDateEndOfPromocode &&
                                        isAfter(el, settedPromocodeEndDate);

                                    // Блокируем часы, недоступные по времени промокода
                                    const disabledByPromocode =
                                        disabledByPromocodeEndDate ||
                                        (!!promocode &&
                                            !!promocode.endTime &&
                                            !!promocode.startTime &&
                                            !_checkWorkingInterval(
                                                promocode.startTime,
                                                promocode.endTime,
                                                el
                                            ));

                                    // Блокируем часы, недоступные с ограниченными по времени категориями
                                    const disabledByCategory =
                                        !!categoriesStartTimeLimits.length &&
                                        !!categoriesEndTimeLimits.length &&
                                        !_checkWorkingInterval(
                                            categoriesStartTimeLimits[0],
                                            categoriesEndTimeLimits[0],
                                            el
                                        );

                                    // Блокируем часы, недоступные с ограничением бонусных продуктов
                                    const disabledByBonusProduct =
                                        !!bonusProduct.id &&
                                        !!config.CONFIG_bonuses_use_limit_time &&
                                        !_checkWorkingInterval(
                                            config.CONFIG_bonuses_start_active,
                                            config.CONFIG_bonuses_end_active,
                                            el
                                        );

                                    // Блокируем часы закрытия сайта
                                    let disabledByMaintenance = false;

                                    if (
                                        getDayOfYear(preorderDate) ===
                                            getDayOfYear(
                                                maintenanceDateStart
                                            ) &&
                                        getDayOfYear(preorderDate) ===
                                            getDayOfYear(maintenanceDateEnd)
                                    ) {
                                        const maintenanceStartTime = set(
                                            new Date(),
                                            {
                                                hours: getHours(
                                                    maintenanceDateStart
                                                ),
                                                minutes:
                                                    getMinutes(
                                                        maintenanceDateStart
                                                    ),
                                                seconds: 0,
                                                milliseconds: 0,
                                            }
                                        );
                                        const maintenanceEndTime = set(
                                            new Date(),
                                            {
                                                hours: getHours(
                                                    maintenanceDateEnd
                                                ),
                                                minutes:
                                                    getMinutes(
                                                        maintenanceDateEnd
                                                    ),
                                                seconds: 0,
                                                milliseconds: 0,
                                            }
                                        );
                                        try {
                                            if (
                                                isWithinInterval(el, {
                                                    start: maintenanceStartTime,
                                                    end: maintenanceEndTime,
                                                })
                                            ) {
                                                disabledByMaintenance = true;
                                            }
                                        } catch (error) {
                                            console.log(
                                                `${error.message}, Something wrong in maintenance interval`
                                            );
                                        }
                                    } else if (
                                        getDayOfYear(preorderDate) ===
                                        getDayOfYear(maintenanceDateStart)
                                    ) {
                                        const maintenanceStartTime = set(
                                            new Date(),
                                            {
                                                hours: getHours(
                                                    maintenanceDateStart
                                                ),
                                                minutes:
                                                    getMinutes(
                                                        maintenanceDateStart
                                                    ),
                                                seconds: 0,
                                                milliseconds: 0,
                                            }
                                        );
                                        if (isAfter(el, maintenanceStartTime)) {
                                            disabledByMaintenance = true;
                                        }
                                    } else if (
                                        getDayOfYear(preorderDate) ===
                                        getDayOfYear(maintenanceDateEnd)
                                    ) {
                                        const maintenanceEndTime = set(
                                            new Date(),
                                            {
                                                hours: getHours(
                                                    maintenanceDateEnd
                                                ),
                                                minutes:
                                                    getMinutes(
                                                        maintenanceDateEnd
                                                    ),
                                                seconds: 0,
                                                milliseconds: 0,
                                            }
                                        );
                                        if (isBefore(el, maintenanceEndTime)) {
                                            disabledByMaintenance = true;
                                        }
                                    }

                                    const itemDisabled =
                                        disabledByPromocode ||
                                        disabledByPromocodesSettings ||
                                        disabledByMaintenance ||
                                        disabledByCategory ||
                                        disabledByBonusProduct ||
                                        disabledByPreorderLimits;

                                    return (
                                        <MenuItem
                                            key={getTime(el)}
                                            value={getTime(el)}
                                            divider={
                                                index < array.length - 1
                                                    ? true
                                                    : false
                                            }
                                            disabled={itemDisabled}
                                            sx={{
                                                justifyContent: "center",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {format(el, "H:mm")}
                                            {(disabledByPreorderLimits && (
                                                <div
                                                    style={{
                                                        fontSize: "12px",
                                                        lineHeight: "1",
                                                    }}
                                                >
                                                    Недоступно
                                                </div>
                                            )) ||
                                                ((disabledByPromocode ||
                                                    disabledByPromocodesSettings) && (
                                                    <div
                                                        style={{
                                                            fontSize: "12px",
                                                            lineHeight: "1",
                                                        }}
                                                    >
                                                        Недоступно с промокодом
                                                    </div>
                                                )) ||
                                                (disabledByCategory && (
                                                    <div
                                                        style={{
                                                            fontSize: "12px",
                                                            lineHeight: "1",
                                                        }}
                                                    >
                                                        Ограничено категорией
                                                    </div>
                                                )) ||
                                                (disabledByBonusProduct && (
                                                    <div
                                                        style={{
                                                            fontSize: "12px",
                                                            lineHeight: "1",
                                                        }}
                                                    >
                                                        Недоступно с подарком
                                                    </div>
                                                ))}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    ) : null}
                </Box>
            </div>
        );
    }
);

export default PreorderForm;
