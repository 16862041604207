import React, { useEffect } from "react";
import useTownNavigate from "../hooks/useTownNavigate.js";
import { useLocation } from "react-router";

const AuthFromVkApp = () => {
    const { navigateTo } = useTownNavigate();
    const { search } = useLocation();

    useEffect(() => {
        navigateTo("/", {}, search);
    }, []);

    return <></>;
};

export default AuthFromVkApp;
